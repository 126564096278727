import { template as template_edd4cc98451d4720800461057ca43b3a } from "@ember/template-compiler";
const FKLabel = template_edd4cc98451d4720800461057ca43b3a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
