import { template as template_e253e67497b24ee2b25298feb7d74bd7 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_e253e67497b24ee2b25298feb7d74bd7(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
