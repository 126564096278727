import { template as template_32fe29954032462e8b6847a347f5aaeb } from "@ember/template-compiler";
const FKText = template_32fe29954032462e8b6847a347f5aaeb(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
